import request from '@/utils/request'
//
export function getProduction(parameter) {
  return request({
    url: '/integral/production',
    method: 'get',
    params: parameter
  })
}

export function queryProductions(parameter) {
  return request({
    url: '/integral/production',
    method: 'post',
    data: parameter
  })
}

// export function removeProduction(parameter) {
//   return request({
//     url: '/production',
//     method: 'delete',
//     params: parameter
//   })
// }
//
export function putProduction(parameter) {
  return request({
    url: '/integral/production',
    method: 'put',
    data: parameter
  })
}

export function upstateProduction(parameter) {
  return request({
    url: '/integral/production/status',
    method: 'post',
    data: parameter
  })
}

// export function stickyProduction(parameter) {
//   return request({
//     url: '/production/sticky',
//     method: 'post',
//     data: parameter
//   })
// }
//
export function getProductionCategory(parameter) {
  return request({
    url: '/production/category',
    method: 'get',
    params: parameter
  })
}

export function putProductionCategory(parameter) {
  return request({
    url: '/production/category',
    method: 'put',
    data: parameter
  })
}

// export function deleteProductionCategory(parameter) {
//   return request({
//     url: '/production/category',
//     method: 'delete',
//     params: parameter
//   })
// }
//
export function queryProductionCategories(parameter) {
  return request({
    url: '/production/category',
    method: 'post',
    data: parameter
  })
}

export function queryProductionEvaluations(parameter) {
  return request({
    url: '/order/production/evaluation',
    method: 'post',
    data: parameter
  })
}

export function upstateProductionEvaluation(parameter) {
  return request({
    url: '/order/production/evaluation/status',
    method: 'post',
    data: parameter
  })
}




